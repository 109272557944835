<template>
  <v-card outlined class="ma-2">
    <v-card-text>
      <v-row>
        <v-col xs="12">
          <v-progress-linear indeterminate color="primary" v-if="processing"></v-progress-linear>
          <v-subheader style="font-size: 16px!important;">Comisiones por Tipo Producto Postpago</v-subheader>
          <div ref="amchart" style="height: 45vh;"></div>
          <br />
          <v-alert
            dense
            type="warning"
            text
            v-show="!processing && isChartEmpty"
          >No existe información para esta gráfica</v-alert>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import apiDashboard from "../../api/dashboards";
import { mapActions } from "vuex";
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themesAnimated from "@amcharts/amcharts4/themes/material";
import { store } from "../../store";
import helper from "../../filters/helper";

am4core.useTheme(am4themesAnimated);

export default {
  props: ["corteId", "tipoProducto"],
  data() {
    return {
      processing: false,
      chartData: [],
      chart: null,
      headers: [
        { text: "PERIODO", value: "Periodo" },
        { text: "CONCEPTO", value: "Concepto" },
        { text: "VALOR", value: "Monto" }
      ]
    };
  },
  computed: {
    isChartEmpty() {
      return this.chartData.length === 0;
    }
  },
  methods: {
    ...mapActions(["setError", "setErrorMessage", "setMessage"]),
    generateChart(dataParam) {
      if (this.chart) {
        this.chart.dispose();
      }

      try {
        let chart = am4core.create(this.$refs.amchart, am4charts.XYChart);
        chart.legend = new am4charts.Legend();
        chart.exporting.menu = new am4core.ExportMenu();
        chart.numberFormatter.numberFormat = "$#,###.##";

        let data = [];
        let componentes = [];
        var cortes = [];
        dataParam.forEach(function(row) {
          if (componentes.indexOf(row.Componente) === -1) {
            if (row.Componente !== "Descuentos") {
              componentes.push(row.Componente);
            }
          }
          if (cortes.indexOf(row.Corte) === -1) {
            cortes.push(row.Corte);
          }
        });

        cortes.forEach(function(d) {
          let rows = dataParam.filter(f => f.Corte === d);
          let newRow = {
            Corte: d,
            Total: 0
          };
          let total = 0;
          componentes.forEach(function(c) {
            let dc = rows.filter(
              f => f.Corte === d && f.Componente === c.replace("_", " ")
            );
            if (dc[0]) {
              newRow[c.replace(" ", "_")] = dc[0].Monto;
              total += dc[0].Monto;
            }
          });
          newRow["Total"] = total;
          data.push(newRow);
        });

        chart.data = data;

        var categoryAxis = chart.xAxes.push(new am4charts.CategoryAxis());
        categoryAxis.dataFields.category = "Corte";
        categoryAxis.title.text = "Corte";
        categoryAxis.renderer.grid.template.location = 0;

        let valueAxis = chart.yAxes.push(new am4charts.ValueAxis());
        valueAxis.renderer.minWidth = 35;

        componentes.forEach(function(c) {
          let series = chart.series.push(new am4charts.ColumnSeries());
          series.dataFields.categoryX = "Corte";
          series.dataFields.valueY = c.replace(" ", "_");
          series.name = c.replace("Reembolso ", "");
          series.stacked = true;
          series.columns.template.width = am4core.percent(80);
          series.columns.template.tooltipText = "{name}: [bold]{valueY}[/]";
        });

        let lineSeries = chart.series.push(new am4charts.LineSeries());
        lineSeries.dataFields.categoryX = "Corte";
        lineSeries.dataFields.valueY = "Total";
        lineSeries.name = "Total";
        lineSeries.strokeWidth = 3;
        var circleBullet = lineSeries.bullets.push(
          new am4charts.CircleBullet()
        );
        circleBullet.circle.stroke = am4core.color("#fff");
        circleBullet.tooltipText = "{name}: [bold]{valueY}[/]";

        this.chart = chart;
      } catch (e) {
        console.log(e);
      }
    },
    refresh() {
      if (this.corteId) {
        this.processing = true;
        let param = {
          CorteId: this.corteId,
          TipoProductoList: Array.from(this.tipoProducto),
          Chart: "EvolucionPostpago",
          AsociadoId: store.getters.user.asociado.id
        };

        apiDashboard
          .getChart(param)
          .then(response => {
            this.chartData = response.data;
            this.generateChart(this.chartData);
            this.processing = false;
          })
          .catch(error => {
            this.setError(error);
            this.processing = false;
          });
      }
    },
    getName(list, id, value, text) {
      return helper.getName(list, id, value, text);
    }
  },
  watch: {
    corteId: function() {
      this.refresh();
    },
    tipoProducto: function() {
      this.refresh();
    }
  },
  mounted() {
    this.refresh();
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
  }
};
</script>

<style scope>
.v-alert.v-sheet.theme--dark.v-alert--dense.warning {
  margin-bottom: 0;
}
</style>